import React, { useContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = React.createContext();

export const useAuth = () => useContext(AuthContext);

const backendUrl = "https://"+process.env.REACT_APP_API_DNS+"/api";

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem("currentUser");
    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser));
    }
    setLoading(false);
  }, []);

  const login = async (email, password) => {
    try {
      const loginURL = backendUrl+"/admin/login";
      const response = await axios.post(loginURL, { email, password });
      if (response.status === 200) {
        const { adminId, accessToken, refreshToken, centerName } = response.data;
        const user = { email, adminId, accessToken, refreshToken, centerName };
        localStorage.setItem("currentUser", JSON.stringify(user));
        setCurrentUser(user);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Failed to log in", error);
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem("currentUser");
    setCurrentUser(null);
  };

  const value = {
    currentUser,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
