import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";

const Section = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  height: 40vh;
  overflow-y: scroll;
`;

const Title = styled.h2`
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  margin-top: 0;
`;

const Divider = styled.hr`
  border: 1px solid #CCCCCC;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  th, td {
    padding: 10px;
    border-bottom: 1px solid #CCCCCC;
  }
  th {
    text-align: left;
  }
  tbody { tr {
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
  }}
`;

const ErrorMessage = styled.p`
  color: red;
`;

const InquiryModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 500px;
  max-height: 80vh;
  overflow-y: auto;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const CloseButton = styled.button`
  background-color: #2C3892;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  float: right;
`;

const InquiryField = styled.div`
  margin-bottom: 15px;
`;

const FieldLabel = styled.span`
  font-weight: bold;
  color: #2C3892;
`;

const InquiryList = () => {
  const { currentUser } = useAuth();
  const [inquiries, setInquiries] = useState([]);
  const [error, setError] = useState("");
  const [selectedInquiry, setSelectedInquiry] = useState(null);

  useEffect(() => {
    const fetchInquiries = async () => {
      try {
        const response = await axios.post(`https://${process.env.REACT_APP_API_DNS}/api/inquiry/get-inquiry/${currentUser.adminId}`, {
          refreshToken: currentUser.refreshToken,
          accessToken: currentUser.accessToken
        });

        if (response.status === 200) {
          setInquiries(response.data);
        } else {
          setError("Failed to fetch inquiries");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Unmatched user Id and token");
        } else if (error.response && error.response.status === 402) {
          setError("You are not an admin");
        } else {
          setError("Error Detected");
        }
      }
    };

    if (currentUser) {
      fetchInquiries();
    }
  }, [currentUser]);

  const handleInquiryClick = (inquiry) => {
    setSelectedInquiry(inquiry);
  };

  const handleCloseModal = () => {
    setSelectedInquiry(null);
  };

  return (
    <Section>
      <Title>문의 목록</Title>
      <Divider />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Table>
        <thead>
          <tr>
            <th>날짜</th>
            <th>이름</th>
            <th>이메일</th>
            <th>내용</th>
          </tr>
        </thead>
        <tbody>
          {inquiries.map((inquiry) => (
            <tr key={inquiry.id} onClick={() => handleInquiryClick(inquiry)}>
              <td>{new Date(inquiry.timestamp).toLocaleDateString()}</td>
              <td>{inquiry.name}</td>
              <td>{inquiry.email}</td>
              <td>{inquiry.content.slice(0, 10)}...</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {selectedInquiry && (
        <>
          <Overlay onClick={handleCloseModal} />
          <InquiryModal>
            <h2>문의 상세</h2>
            <InquiryField>
              <FieldLabel>날짜:</FieldLabel> {new Date(selectedInquiry.timestamp).toLocaleDateString()}
            </InquiryField>
            <InquiryField>
              <FieldLabel>이름:</FieldLabel> {selectedInquiry.name}
            </InquiryField>
            <InquiryField>
              <FieldLabel>전화번호:</FieldLabel> {selectedInquiry.phone}
            </InquiryField>
            <InquiryField>
              <FieldLabel>이메일:</FieldLabel> {selectedInquiry.email}
            </InquiryField>
            <InquiryField>
              <FieldLabel>유저IP:</FieldLabel> {selectedInquiry.userIP}
            </InquiryField>
            <InquiryField>
              <FieldLabel>내용:</FieldLabel> {selectedInquiry.content}
            </InquiryField>
            <CloseButton onClick={handleCloseModal}>닫기</CloseButton>
          </InquiryModal>
        </>
      )}
    </Section>
  );
};

export default InquiryList;
