import React, { useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

const ChangePasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #F4F4F4;
`;

const Title = styled.h1`
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 10px;
`;


const Description = styled.p`
  color: #7B7B7B;
  font-size: 14px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 30px;
  text-align: center;
`;

const ChangePasswordSection = styled.div`
  background-color: #FFFFFF;
  border: 1px solid #C1C1C1;
  border-radius: 20px;
  padding: 50px 70px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  width: 410px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputGroup = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const InputLabel = styled.label`
  display: block;
  color: #2B3674;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.02em;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  height: 50px;
  padding: 0 10px;
  border: 1px solid #D9D9D9;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.02em;
  color: #C1C1C1;
  box-sizing: border-box;
`;

const PasswordInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const PasswordInput = styled(Input)`
`;

const EyeIcon = styled.svg`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const Button = styled.button`
  background-color: ${props => props.disabled ? '#CCCCCC' : '#2C3892'};
  color: #FFFFFF;
  border: none;
  border-radius: 15px;
  padding: 15px 0;
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  &:hover {
    background-color: ${props => props.disabled ? '#CCCCCC' : '#1A237E'};
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 12px;
  margin-bottom: 0px;
`;

const EyeIconOpen = ({ onClick }) => (
    <EyeIcon viewBox="0 0 24 24" onClick={onClick}>
      <path
        d="M12 4.5C7.30558 4.5 3.24779 7.05547 1.5 11.25C3.24779 15.4445 7.30558 18 12 18C16.6944 18 20.7522 15.4445 22.5 11.25C20.7522 7.05547 16.6944 4.5 12 4.5ZM12 15C10.067 15 8.5 13.433 8.5 11.5C8.5 9.567 10.067 8 12 8C13.933 8 15.5 9.567 15.5 11.5C15.5 13.433 13.933 15 12 15ZM12 10C11.1756 10 10.5 10.6756 10.5 11.5C10.5 12.3244 11.1756 13 12 13C12.8244 13 13.5 12.3244 13.5 11.5C13.5 10.6756 12.8244 10 12 10Z"
        fill="#2B3674"
      />
    </EyeIcon>
);
  
const EyeIconClosed = ({ onClick }) => (
    <EyeIcon viewBox="0 0 24 24" onClick={onClick}>
      <svg fill="none" height="24" viewBox="0 0 24 24" width="24">
        <g stroke="#2B3674" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
            <path d="m2 2 20 20"/>
            <path d="m6.71277 6.7226c-3.04798 2.07267-4.71277 5.2774-4.71277 5.2774s3.63636 7 10 7c2.0503 0 3.8174-.7266 5.2711-1.7116m-6.2711-12.23018c.3254-.03809.6588-.05822 1-.05822 6.3636 0 10 7 10 7s-.6918 1.3317-2 2.8335"/>
            <path d="m14 14.2362c-.5308.475-1.2316.7639-2 .7639-1.6569 0-3-1.3431-3-3 0-.8237.33193-1.5698.86932-2.11192"/>
        </g>
      </svg>
    </EyeIcon>
);

const ChangePasswordPage = () => {
  const currentPasswordRef = useRef();
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();
  const { currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handlePasswordVisibility = (field) => {
    setPasswordVisible((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("비밀번호가 일치하지 않습니다");
      return;
    }

    if (newPassword.length < 8) {
      setError("비밀번호는 8자리 이상이어야 합니다");
      return;
    }

    try {
      setError("");
      setLoading(true);
      const response = await axios.put(`https://${process.env.REACT_APP_API_DNS}/api/admin/change-password/${currentUser.adminId}`, {
        refreshToken: currentUser.refreshToken,
        accessToken: currentUser.accessToken,
        currentPassword: currentPasswordRef.current.value,
        newPassword: newPassword,
      });

      if (response.status === 200) {
        navigate("/admin");
      } else {
        setError("비밀번호 변경에 실패했습니다");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Unmatched user Id and token");
      } else if (error.response && error.response.status === 404) {
        setError("Admin not found");
      } else if (error.response && error.response.status === 402) {
        setError("Incorrect current password");
      } else {
        setError("Error Detected");
      }
    }

    setLoading(false);
  };

  return (
    <ChangePasswordContainer>
      <Title>비밀번호 변경</Title>
      <Description>
        기존 비밀번호를 잊으신 경우에는
        <br />
        bannangko@gmail.com으로 메일을 부탁 드립니다.
      </Description>
      <ChangePasswordSection>
        <Form onSubmit={handleSubmit}>
          <InputGroup>
            <InputLabel htmlFor="email">아이디</InputLabel>
            <Input id="email" type="email" defaultValue={currentUser.email} disabled />
          </InputGroup>
          <InputGroup>
            <InputLabel htmlFor="currentPassword">비밀번호</InputLabel>
            <PasswordInputWrapper>
              <PasswordInput
                id="currentPassword"
                type={passwordVisible.current ? "text" : "password"}
                ref={currentPasswordRef}
                required
                placeholder="현재 비밀번호를 입력해 주세요"
              />
              {passwordVisible.current ? (
                <EyeIconClosed onClick={() => handlePasswordVisibility("current")} />
              ) : (
                <EyeIconOpen onClick={() => handlePasswordVisibility("current")} />
              )}
            </PasswordInputWrapper>
          </InputGroup>
          <InputGroup>
            <InputLabel htmlFor="newPassword">새 비밀번호</InputLabel>
            <PasswordInputWrapper>
              <PasswordInput
                id="newPassword"
                type={passwordVisible.new ? "text" : "password"}
                ref={newPasswordRef}
                required
                placeholder="8자리 이상의 새로운 비밀번호를 입력해 주세요"
                value={newPassword}
                onChange={handleNewPasswordChange}
              />
              {passwordVisible.new ? (
                <EyeIconClosed onClick={() => handlePasswordVisibility("new")} />
              ) : (
                <EyeIconOpen onClick={() => handlePasswordVisibility("new")} />
              )}
            </PasswordInputWrapper>
            {newPassword && newPassword.length < 8 && (
              <ErrorMessage>비밀번호는 8자리 이상이어야합니다.</ErrorMessage>
            )}
          </InputGroup>
          <InputGroup>
            <InputLabel htmlFor="confirmPassword">새 비밀번호 확인</InputLabel>
            <PasswordInputWrapper>
              <PasswordInput
                id="confirmPassword"
                type={passwordVisible.confirm ? "text" : "password"}
                ref={confirmPasswordRef}
                required
                placeholder="새로운 비밀번호를 다시 입력해 주세요"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              {passwordVisible.confirm ? (
                <EyeIconClosed onClick={() => handlePasswordVisibility("confirm")} />
              ) : (
                <EyeIconOpen onClick={() => handlePasswordVisibility("confirm")} />
              )}
            </PasswordInputWrapper>
            {newPassword && confirmPassword && newPassword !== confirmPassword && (
              <ErrorMessage>비밀번호가 일치하지 않습니다</ErrorMessage>
            )}
          </InputGroup>
          <Button disabled={loading || newPassword.length < 8 || newPassword !== confirmPassword} type="submit">
            비밀번호 변경
          </Button>
        </Form>
      </ChangePasswordSection>
    </ChangePasswordContainer>
  );
};

export default ChangePasswordPage;
