import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";

const Section = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')}; // 날짜 선택되면 block
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  height: 70vh;
  overflow-y: scroll;
`;

const BookingDetailsHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const Title = styled.h2`
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  margin-top: 0;
`;

const SelectStatus = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  button {
    min-width: 80px;
    background-color: white;
    margin: 0 2px;
    border: #7272ff 1px solid;
    border-radius: 20px;
    padding: 5px;
    cursor: pointer;
  }
`;

const Divider = styled.hr`
  border: 1px solid #CCCCCC;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  th, td {
    padding: 10px;
    border-bottom: 1px solid #CCCCCC;
  }
  th {
    text-align: left;
  }
`;

const TableCell = styled.td`
  vertical-align: middle;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

const BookingDetails = ({selectedDay}) => {
  const { currentUser } = useAuth();
  const [bookingDetails, setBookingDetails] = useState([]);
  const [error, setError] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [showDateInput, setShowDateInput] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [newDate, setNewDate]= useState("");
  const [loading, setLoading] = useState(false);
  const [filterState, setFilterState] = useState({
    passingTags: {
      ALL: true,
      BOOK: false,
      PAID: false,
      CANCEL: false,
      USE: false,
      COMPLETE: false,
      Others: false, // 예시 배열에 booking.status = null 이 있어서 추가함
    },
  });

  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const response = await axios.post(`https://${process.env.REACT_APP_API_DNS}/api/admin/bookings-detail/${currentUser.adminId}`, {
          refreshToken: currentUser.refreshToken,
          accessToken: currentUser.accessToken
        });

        if (response.status === 200) {
          setBookingDetails(response.data);
          // console.log("response data is", response.data);
        } else {
          setError("Failed to fetch booking details");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Unmatched user Id and token");
        } else if (error.response && error.response.status === 404) {
          setError("아직 예약 내역이 존재하지 않습니다.");
        } else {
          setError("Error Detected");
        }
      }
    };

    if (currentUser) {
      fetchBookingDetails();
    }
  }, [currentUser]);


  useEffect(() => {
    if(selectedDay){
      // console.log(selectedDay);
      const temp = bookingDetails.filter(
        (booking) => booking[selectedDay.order].split('T')[0] === selectedDay.day
      );

      // 필터링 기능
      const now = filterState.passingTags;
      let filtered = [];
      if(now.ALL) filtered = temp;
      else {
        for(let i=0; i<temp.length; i++){
          for(let j in now){
            if(j === "Others"){
              if(now[j] && temp[i].status === null) {
                filtered.push(temp[i]);
              }
            }
            else if(now[j] && temp[i].status === j) {
              filtered.push(temp[i]);
            }
          }
        }
      }
      // console.log(filtered);
      setFilteredBookings(filtered);
    }}, [selectedDay, bookingDetails, filterState.passingTags]);
  
  if (!selectedDay) {
    return null; // 선택된 예약이 없으면 아무것도 렌더링하지 않음
  }

  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index);
  };

  const handleRefund = async (paymentId) => {
    try {
      // console.log("paymentKey is", paymentId);
      setLoading(true);
      const response = await axios.post(`https://${process.env.REACT_APP_API_DNS}/api/payment/cancellation`, {
        paymentId: paymentId
      });

      if (response.status === 200) {
        alert("환불이 성공적으로 처리되었습니다.");
        // Optionally refresh booking details here after refund
      } else {
        alert("환불 처리에 실패했습니다.");
      }
    } catch (error) {
      console.error("Error during refund:", error);
      alert("환불 중 오류가 발생했습니다.");
    } finally {
      setLoading(false);
    }
  };

  const handleFilterStatus = (e) => {
    const type = e.target.id;
    // console.log(type);

    if(type === "ALL"){
      setFilterState({
        passingTags:{
          ALL: !filterState.passingTags[type],
          BOOK: false,
          PAID: false,
          CANCEL: false,
          USE: false,
          COMPLETE: false,
          Others: false
        }
      });
      return;
    }

    let temp = {...filterState.passingTags, [type]: !filterState.passingTags[type],};
    
    if(temp.ALL){
      temp.ALL = !temp.ALL;

    }
    else if(temp.BOOK && temp.PAID && temp.CANCEL && temp.USE && temp.COMPLETE && temp.Others){
      temp = {
        ALL: true,
        BOOK: false,
        PAID: false,
        CANCEL: false,
        USE: false,
        COMPLETE: false,
        Others: false
      };
    }
    setFilterState({
      passingTags:temp,
    });
  };

  const handleStatusUpdate = async (booking, newStatus) => {
    try {
      setLoading(true);
      const response = await axios.patch(`https://${process.env.REACT_APP_API_DNS}/api/booking/user-booking/${booking.id}`, {
        refreshToken: currentUser.refreshToken,
        accessToken: currentUser.accessToken,
        status: newStatus,
        adminId: currentUser.adminId,
      });

      if (response.status === 200) {
        alert('예약 상태가 성공적으로 업데이트되었습니다.');
        
        console.log("response is", response);
        // Update the booking status in the local state
        const updatedBookingDetails = bookingDetails.map(b => {
          if (b.id === booking.id) {
            return { ...b, status: newStatus };
          }
          return b;
        });
        setBookingDetails(updatedBookingDetails);
      } else {
        alert('예약 상태 업데이트에 실패했습니다.');
      }
    } catch (error) {
      console.error('Error updating booking status:', error);
      alert('예약 상태 업데이트 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (status) => {
    switch(status){
      case "BOOK":
        return "#e8f4fe";
      case "PAID":
        return "#ffecca";
      case "CANCEL":
        return "#ffdcdc";
      case "USE":
        return "#e9ffe9";
      case "COMPLETE":
        return "#f3f3f3";
      default:
        return "white";
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case "BOOK":
        return "예약완료";
      case "PAID":
        return "결제완료";
      case "CANCEL":
        return "취소됨";
      case "USE":
        return "이용중";
      case "COMPLETE":
        return "이용완료";
      default:
        return "상태 없음";
    }
  };

  const ChangeTicketDate = (book) => { // 날짜 변경페이지 열기
    setSelectedBooking(book);
    setShowDateInput(!showDateInput);
  };

  const handleDateChange = (e) => {
    setNewDate(new Date(e.target.value).toISOString().split('T')[0]);
  }

  const handleDateUpdate = async () => {
    if(!newDate || !selectedBooking){
      alert("날짜를 선택해주세요.");
      return;
    }
    try{
      setLoading(true);
      const response = await axios.patch(`https://${process.env.REACT_APP_API_DNS}/api/booking/user-booking/${selectedBooking.id}`, {
        refreshToken: currentUser.refreshToken,
        accessToken: currentUser.accessToken,
        selectedDay: newDate,
        status: selectedBooking.status
      });
      if(response.status === 200){
        alert("날짜가 성공적으로 변경되었습니다");
        window.location.reload();
        setShowDateInput(false);
      }else{
        alert("날짜 변경에 실패했습니다.");
      }
    }catch(error){
      console.error("Error: ", error);
    }finally{
      setLoading(false);
    }
  };

  return (
    <Section show={selectedDay.day}>
      <BookingDetailsHeader>
        <div><Title>{`${selectedDay.day} 예약 내용 상세`}</Title></div>
        <SelectStatus>
          <button id="ALL" onClick={handleFilterStatus}
            style={{
              backgroundColor: filterState.passingTags.ALL ? '#7272ff' : 'white',
              color: filterState.passingTags.ALL ? 'white' : 'black',
            }}>
            전체
          </button>
          <button id="BOOK" onClick={handleFilterStatus}
            style={{
              backgroundColor: filterState.passingTags.BOOK ? '#7272ff' : 'white',
              color: filterState.passingTags.BOOK ? 'white' : 'black',
            }}>
            결제전
          </button>
          <button id="CANCEL" onClick={handleFilterStatus}
            style={{
              backgroundColor: filterState.passingTags.CANCEL ? '#7272ff' : 'white',
              color: filterState.passingTags.CANCEL ? 'white' : 'black',
            }}>
            결제 취소
          </button>
          <button id="PAID" onClick={handleFilterStatus}
            style={{
              backgroundColor: filterState.passingTags.PAID ? '#7272ff' : 'white',
              color: filterState.passingTags.PAID ? 'white' : 'black',
            }}>
            결제완료(이용전)
          </button>
          <button id="USE" onClick={handleFilterStatus}
            style={{
              backgroundColor: filterState.passingTags.USE ? '#7272ff' : 'white',
              color: filterState.passingTags.USE ? 'white' : 'black',
            }}>
            결제완료(이용중)
          </button>
          <button id="COMPLETE" onClick={handleFilterStatus}
            style={{
              backgroundColor: filterState.passingTags.COMPLETE ? '#7272ff' : 'white',
              color: filterState.passingTags.COMPLETE ? 'white' : 'black',
            }}>
            이용완료
          </button>
          {/* 예시 배열에 booking.status = null 이 있어서 추가 */}
          <button id="Others" onClick={handleFilterStatus}
            style={{
              backgroundColor: filterState.passingTags.Others ? '#7272ff' : 'white',
              color: filterState.passingTags.Others ? 'white' : 'black',
            }}>
            그 외
          </button> 
        </SelectStatus>
      </BookingDetailsHeader>
      <Divider />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Table>
        <thead>
          <tr>
            <th>예약자명</th>
            <th>전화번호</th>
            <th>아이템 종류</th>
            <th>예약 수량</th>
            <th>지불금액</th>
            <th>예약 종류</th>
            <th>예약 일시</th>
            <th>이용 일시</th>
            <th>paymentId</th>
          </tr>
        </thead>
        <tbody>
          {filteredBookings.length === 0 ? 
            (
            <tr>
              <td colSpan="8">선택된 날짜에 예약 내역이 없습니다.</td>
            </tr>
            ) : (
              filteredBookings.map((booking, index) => (
                <>
                  <tr
                    key={index}
                    onClick={() => handleRowClick(index)}
                    style={{
                      cursor: 'pointer',
                      // 원래
                      // backgroundColor: selectedRow === index ? '#f0f8ff' : 'transparent', // 선택된 tr에 음영
                      // 변경
                      border: selectedRow === index ? 'gray 3px solid' : 'transparent',
                      backgroundColor: getStatusColor(booking.status),
                    }}
                  >
                    <TableCell>{booking.userName}</TableCell>
                    <TableCell>{booking.phoneNumber}</TableCell>
                    <TableCell>{booking.itemId}</TableCell>
                    <TableCell>{booking.totalQuantity.toLocaleString()}</TableCell>
                    <TableCell>{booking.finalPrice.toLocaleString()}</TableCell>
                    <TableCell>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {/* Display booking status */}
                        <span>{`상태: ${getStatusText(booking.status)}`}</span>
                        {Object.entries(booking.selectedOptions).map(([key, value]) =>
                          value > 0 ? <span key={key}>{`${key} x ${value}`}</span> : null
                        )}
                      </div>
                    </TableCell>
                    <TableCell>{booking.timestamp.toLocaleString()}</TableCell>
                    <TableCell>{booking.selectedDay}</TableCell>
                    <TableCell>{booking.paymentId}</TableCell>
                  </tr>
                  {selectedRow === index && (
                    <tr>
                      <TableCell colSpan={8}>
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', padding: '10px 0' }}>
                          <button onClick={() => handleStatusUpdate(booking, 'PAID')}>예약완료</button>
                          <button onClick={() => handleStatusUpdate(booking, 'CANCEL')}>이용취소</button>
                          <button onClick={() => handleStatusUpdate(booking, 'COMPLETE')}>이용완료</button>
                          <button onClick={() => ChangeTicketDate(booking)}>날짜변경</button>
                          <button onClick={() => handleRefund(booking.paymentId)} disabled={loading}>
                            {loading ? '환불 처리 중...' : '환불하기'}
                          </button>
                        </div>
                        {showDateInput && selectedBooking === booking && (
                          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', padding: '10px 0' }}>
                            <input type="date"
                              onChange={handleDateChange}
                              defaulValue={new Date(booking.selectedDay).toISOString().split('T')[0]} />
                            <button onClick={handleDateUpdate} disabled={loading}>
                              {loading ? '변경 중...' : '변경'}
                            </button>
                          </div>
                        )}
                      </TableCell>
                    </tr>
                  )}
                </>
              ))
            )}
        </tbody>
      </Table>
      {/* <ChangeTicketDate /> */}
    </Section>
  );
};

export default BookingDetails;
