import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import AdminPage from "./pages/AdminPage";
import LoginPage from "./pages/LoginPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import PrivateRoute from "./utils/PrivateRoute";


const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/admin" element={<PrivateRoute element={AdminPage} />} />
          <Route path="/change-password" element={<PrivateRoute element={ChangePasswordPage} />} />
          <Route path="*" element={<Navigate to="/admin" />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
