import React, { useState } from "react";
import styled from "styled-components";

import Sidebar from "../components/Sidebar";
import BookingList from "../components/BookingList";
import BookingDetails from "../components/BookingDetails";
import InquiryList from "../components/InquiryList";
import Memo from "../components/Memo";
import { useAuth } from "../contexts/AuthContext";

const Container = styled.div`
  display: flex;
  // height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  background-color: #F4F4F4;
  padding: 10px 20px;
`;

const Title = styled.h1`
  color: #000000;
  margin-top: 15px;
  font-size: 24px;
`;

const SectionContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
`;


const AdminPage = () => {
  const { currentUser } = useAuth();
  const [selectedDay, setSelectedDay] = useState(null); // 선택된 날짜
  return (
      <Container>
          <Sidebar />
          <ContentContainer>
              <Title>예약 목록</Title>
              <SectionContainer>
                  <BookingList setSelectedDay={setSelectedDay}/>
                  <BookingDetails selectedDay={selectedDay}/>
              </SectionContainer>
              {/* {currentUser && currentUser.email === "bannangko@gmail.com" && <InquiryList />} */}
              {/* <Memo /> */}
          </ContentContainer>
      </Container>
  );
};

export default AdminPage;
