import React, { useState } from "react";
import styled from "styled-components";

const Section = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  color: #000000;
`;

const Divider = styled.hr`
  border: 1px solid #CCCCCC;
`;

const MemoSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MemoTextArea = styled.textarea`
  flex: 1;
  padding: 10px;
  font-size: 16px;
`;

const SaveButton = styled.button`
  background-color: #2C3892;
  color: #FFFFFF;
  border: none;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #1A237E;
  }
`;

const Memo = () => {
  const [memo, setMemo] = useState("");

  const saveMemo = () => {
    // Save memo logic
    alert("Memo saved!");
  };

  return (
    <MemoSection>
      <Section>
        <Title>메모</Title>
        <Divider />
        <MemoTextArea value={memo} onChange={(e) => setMemo(e.target.value)} placeholder="여기에 메모해 주세요" />
        <SaveButton onClick={saveMemo}>메모 저장</SaveButton>
      </Section>
    </MemoSection>
  );
};

export default Memo;
