import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import ReactPaginate from "react-paginate";

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  ul {
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 5px;
    list-style-type: none; /* li 태그의 기본 목록 스타일을 제거 */
  }

  a {
    padding: 5px 10px;
    border: 1px solid #2C3892; //#007bff;
    color: #2C3892;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background-color: #2C3892;
      color: white;
    }
  }

  .active a {
    background-color: #2C3892;
    color: white;
  }
`;

const Section = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
`;

const BookingListHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  margin-top: 0;
`;

const OrderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const OrderLabel = styled.span`
  margin-right: 10px;
  font-size: 14px;
`;

const SelectOrder = styled.select`
  padding: 5px 10px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  background-color: #FFFFFF;
  font-size: 14px;
  cursor: pointer;
`;

const Divider = styled.hr`
  border: 1px solid #CCCCCC;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  th, td {
    padding: 10px;
    border-bottom: 1px solid #CCCCCC;
  }
  th {
    text-align: left;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

const BookingList = ({setSelectedDay}) => {
  const { currentUser } = useAuth();
  const [bookings, setBookings] = useState({ bookings: [], bookings_by_timestamp: [] });
  const [error, setError] = useState("");
  const [activePage, setActivePage] = useState(0);
  const [order, setOrder] = useState("timestamp");
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        console.log("currentUser is", currentUser);
        const response = await axios.post(`https://${process.env.REACT_APP_API_DNS}/api/admin/bookings-date/${currentUser.adminId}`, {
          refreshToken: currentUser.refreshToken,
          accessToken: currentUser.accessToken
        });

        if (response.status === 200) {
          setBookings(response.data);
          console.log("bookings are ", response.data);
        } else {
          setError("Failed to fetch bookings");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Unmatched user Id and token");
        } else if (error.response && error.response.status === 404) {
          setError("아직 예약 내역이 존재하지 않습니다.");
        } else {
          setError("Error Detected");
        }
      }
    };

    if (currentUser) {
      fetchBookings();
    }
  }, [currentUser]);


  let dataList = order === "selectedDay" ? bookings.bookings : bookings.bookings_by_timestamp;

  if (dataList && dataList.length > 0) {
    dataList.sort((a, b) => {
      if (order === "timestamp") {
        // Descending order - "예약 신청 날짜"
        return new Date(b.timestamp) - new Date(a.timestamp);
      } else {
        // Ascending order - "이용 날짜"
        return new Date(a.selectedDay) - new Date(b.selectedDay);
      }
    });
  }

  // 예약신청날짜 기준 - 첫 페이지가 activePage
  // 이용예정날짜 기준 - 오늘 날짜가 있는 페이지가 activePage
  useEffect(() => {
    if (!dataList || dataList.length === 0) {
      return;
    }
    if (order === "timestamp") {
      setActivePage(0);
    } else if (order === "selectedDay") {
      const today = new Date().toISOString().split("T")[0];
  
      let index = dataList.findIndex((data) => data.selectedDay === today);
  
      if (index === -1) { // If today's date is not in the list, default to the first page
        setActivePage(0);
      } else {
        let pageIndex = Math.floor(index / itemsPerPage);
        setActivePage(pageIndex);
      }
    }
  }, [order, bookings]); // 순서 변경 또는 bookings 변경


  // const getDays = () => {
  //   let today = new Date();
  //   let days = [];
  //   for (let i = 0; i < 365; i++) { // 1년
  //     const day = new Date(today);
  //     // day.setMonth(today.getMonth() - 2); // 2개월 전 ~ 
  //     // day.setDate(today.getDate() + i);
  //     day.setDate(today.getDate() - 7 + i); // 1주일 전 ~
  //     days.push(day.toISOString().split('T')[0]);
  //   }
  //   return days;
  // };


  const handleRowClick = (day) => {
    setSelectedDay({ day, order });
  };

  // 페이지네이션을 위한 예약 리스트 분리
  const indexOfLastBooking = (activePage + 1) * itemsPerPage; // 한 페이지에 표시할 마지막 일자
  const indexOfFirstBooking = indexOfLastBooking - itemsPerPage; // 한 페이지에 표시할 첫 일자
  const paginatedBookings = dataList.slice(indexOfFirstBooking, indexOfLastBooking); // 현재 페이지에 맞는 일자들 분리

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber.selected);
  };

  const SelectOrderFunc = (e) =>{
    const type = e.target.value;
    setOrder(type);
  };

  return (
    <Section>
      <BookingListHeader>
        <div><Title>예약 내역</Title></div>
        <OrderContainer>
          <OrderLabel>정렬기준</OrderLabel>
          <SelectOrder name="order" id="order" value={order} onChange={SelectOrderFunc}>
            <option value="timestamp">예약 신청 날짜</option>
            <option value="selectedDay">이용 날짜</option>
          </SelectOrder>
        </OrderContainer>
      </BookingListHeader>
      <Divider />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Table>
        <thead>
          <tr>
            <th>{order === "selectedDay" ?  "이용 날짜": "예약 신청 날짜"}</th>
            <th>예약수</th>
            <th>예약인원</th>
            <th>카드결제금액</th>
          </tr>
        </thead>
        <tbody>
        {paginatedBookings.map((data, index) => {
            const day = order === "selectedDay" ? data.selectedDay : data.timestamp;

            const totalQuantity = order === "selectedDay" ? data.totalQuantity : data.totalPeople;
            const totalPrice = order === "selectedDay" ? data.finalPrice : data.totalTake;
            const totalCount = data.totalCount;
            // const totalQuantity = dailyBookings.reduce(
            //   (sum, booking) => sum + parseInt(
            //     order === "selectedDay"
            //     ? booking.totalQuantity
            //     : booking.totalPeople,
            //     10), 0
            // );
            // const totalPrice = dailyBookings.reduce(
            //   (sum, booking) => sum + parseInt(
            //     order === "selectedDay" ? booking.finalPrice : booking.totalTake,
            //     10), 0
            // );
            return (
              <tr
                key={index}
                onClick={() => handleRowClick(day)}
                style={{ cursor: "pointer" }}
              >
                <td>{day}</td>
                <td>{totalCount}</td>
                <td>{totalQuantity}</td>
                <td>{parseInt(totalPrice, 10).toLocaleString()}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      {/* 페이지네이션 컴포넌트 */}
      <PaginationContainer>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          pageCount={Math.ceil(dataList.length / itemsPerPage)}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          forcePage={activePage}
          activeClassName={"active"}
        />
      </PaginationContainer>
    </Section>
  );
};


export default BookingList;
